body {
  margin: 0;
  padding: 0;
}
@font-face {
  font-family: Inter-Bold;
  src: url(../../../public/Inter/static/Inter-Bold.ttf);
}
@font-face {
  font-family: Inter-SemiBold;
  src: url(../../../public/Inter/static/Inter-SemiBold.ttf);
}
@font-face {
  font-family: Inter-Medium;
  src: url(../../../public/Inter/static/Inter-Medium.ttf);
}
@font-face {
  font-family: Inter-Regular;
  src: url(../../../public/Inter/static/Inter-Regular.ttf);
}
/* splashScreen style */

.mainWrap {
  background-color: #f5f8ff;
  min-height: 100vh;
  padding-top: 20px;
}
.padIn {
  padding: 24px 32px 40px 32px;
}
.logoCntr {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.logoStyle {
  max-width: 100%;
}
.title {
  width: 245px;
  height: auto;
}
.splashScreen {
  height: 535px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homePad {
  padding: 20px 160px 100px 160px !important;
}
.padTB {
  padding: 40px 0;
}
.hdg {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Inter-Bold";
  color: #344054;
}
.subHdg {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  font-family: "Inter-SemiBold";
  color: #344054;
}
.subTxt {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Inter-Regular";
  color: #344054;
  margin-top: 20px;
}
.subTxtRW {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #344054;
  margin-top: 30px;
}
.cntr {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.VWOcard {
  position: relative;
  width: 160px;
  height: 160px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 8px 0;
  box-shadow: 0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
}
.iconBtm {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.VWOcard p {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: "Inter-SemiBold";
  color: #667085;
}
.rot1 {
  transform: rotate(-5deg);
}
.rot2 {
  transform: rotate(-2deg);
}
.rot3 {
  transform: rotate(2deg);
}
.rot4 {
  transform: rotate(-3deg);
}
.orngBrdr {
  border: 2px solid #f97066;
}
.smHgt {
  height: 120px !important;
}
.bluBrdr {
  border: 2px solid #8098f9;
}
.indctor {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  padding-bottom: 30px;
  background-color: #f5f8ff;
  .row {
    .relate-it {
      position: absolute;
      left: 94%;
      bottom: -4px;
    }
  }
}
.nextbutton {
  color: #3c3fce !important;
  font-weight: 700 !important;
}

@media screen and (max-width: 992px) {
  .indctor {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
    background-color: #f5f8ff;
    .row {
      .relate-it {
        position: absolute;
        left: 97%;
        bottom: 30px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .indctor {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
    background-color: #f5f8ff;
    .row {
      .relate-it {
        position: absolute;
        left: 95%;
        bottom: 26px;
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .indctor {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
    background-color: #f5f8ff;
    .row {
      .relate-it {
        position: absolute;
        left: 83%;
        bottom: 30px;
      }
    }
  }
}

@media screen and (max-width: 393px) {
  .indctor {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px;
    padding-bottom: 30px;
    background-color: #f5f8ff;
    .row {
      .relate-it {
        position: absolute;
        left: 83%;
        bottom: 32px;
      }
    }
  }
  .nextbutton {
    margin-left: -30px;
  }
}
/* loginScreen style */

.clWrap {
  display: flex;
  background-color: #ffffff;
  height: 100vh;
}
@media screen and (max-height: 670px) {
  .clWrap {
    height: 113vh;
  }
}
@media screen and (max-height: 570px) {
  .clWrap {
    height: 140vh;
  }
}
@media screen and (max-height: 470px) {
  .clWrap {
    height: 150vh;
  }
}

.clLft-side {
  width: 340px;
  background-color: #f9fafb;
  padding: 40px 60px 40px 60px;
}
.clLogoStyle {
  width: 100px;
  /* height: 33px; */
  /* max-width: 100%; */
  height: auto;
  object-fit: contain;
  position: fixed;
}
.illustrationContainer {
  bottom: 50px;
  position: fixed;
}

.illustImg {
  /* max-width: 100%; */
  height: auto;
}
.formSpc {
  flex-grow: 1;
  padding: 80px 120px 80px 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginCode {
  width: 31rem;
}
.formArea {
  height: 100px;
  margin-bottom: 10px;
}
.loginCode p {
  font-size: 30px;
  font-weight: 700;
  line-height: 38px;
  font-family: "Inter-Bold";
  color: #0c111d;
}

.loginCode label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  font-family: "Inter-Medium";
  color: #344054;
}

.loginCode .formArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.loginCode .formArea input[type="text"].error {
  border: 1px solid #fda29b;
  box-shadow: 0px 0px 0px 4px #fee4e2, 0px 1px 2px 0px #1018280d;
}
.input-groupp {
  display: flex;
}
.loginCode .formArea input[type="text"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.input-group {
  position: relative;
  display: inline-block;
}

.keyIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}
.helpIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  right: 8px;
}

#codeInput {
  padding-left: 33px;
  padding-right: 33px;
}

.loginCode .instructionTxt {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  font-family: "Inter-Regular";
  color: #475467;
}
.loginCode .instructionTxtError {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #f04438;
}
.make-red-border {
  border: 1px solid red;
  border-radius: 3px;
}
.btnArea {
  display: flex;
  justify-content: center;
}

.btnVldt {
  border: 1px solid #444ce7;
  background-color: #444ce7;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  gap: 8px;
  font-size: 14px;
}
.btnVldt:hover {
  background-color: #3538cd;
  border: 1px solid #3538cd;
  color: #ffffff;
}
.btnVldt.disabled {
  background-color: #c7d7fe;
  border: 1px solid #c7d7fe;
  color: #ffffff;
}
.yesno .yesnolabel {
  color: #667085;
}
.yesno .yesnolabel:hover {
  color: #344054;
}
.yesno .yesnoinput {
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #d0d5dd;
}
.yesno .yesnoinput:hover {
  appearance: none;
  background-color: #e0eaff;
  border: 1px solid #444ce7;
}
.yesno input[type="radio"]:checked {
  appearance: none;
  display: block;
  border-radius: 50%;
  background: #444ce7;
  border: 6px solid #eef4ff;
  outline: 1px solid #444ce7;
}

/* .yesno input[type="radio"]:checked::before{
  content: '';
    display: block;
    border-radius: 50%;
    background: #3b82f6;
} */
/* .yesno .form-check-input:checked{
  border: 1px solid #444CE7;
  background-color: #EEF4FF;
} */

.btnVldt p {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  color: #ffffff;
  margin: 0;
}

.arrowIconStyl {
  width: 20px;
  height: 20px;
}
.CPD-Area {
  padding: 16px 8px 16px 8px;
  border-width: 1px 0px 1px 0px;
  border-style: solid;
  border-color: var(--30-black, #d0d5dd);
  gap: 8px;
  margin-bottom: 20px;
}
.CPD-Area .instructionTxt b {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  font-family: "Inter-SemiBold";
  color: #475467;
}
.dataFormArea {
  padding: 0 5px;
}
.dataFormArea .instructionTxt {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #475467;
}
.loginCode .dataFormArea input[type="tel"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .dataFormArea input[type="number"]::webkit-outer-spin-button,
.loginCode .dataFormArea input[type="number"]::webkit-inner-spin-button {
  display: none;
  --webkit-appearance: none;
  margin: 0;
}
.loginCode .dataFormArea input[type="tel"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.loginCode .dataFormArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .dataFormArea input[type="text"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
#nameInput,
#emailInput,
#contactInput {
  padding-left: 33px;
  padding-right: 33px;
}
.userIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}
.emailIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}
.contactIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  left: 8px;
}

/* AdditionalInformation style */

.addInfsubTxt p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: "Inter-Regular";
  color: #101828;
}
.loginCode .addInfrmtnArea input[type="text"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  overflow: hidden;
  outline: none;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .addInfrmtnArea input[type="number"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.loginCode .addInfrmtnArea input[type="text"]:focus,
.loginCode .addInfrmtnArea input[type="number"]:focus {
  outline: none;
  border-color: #8098f9;
  box-shadow: 0px 0px 0px 3px hsl(267, 100%, 96%), 0px 1px 2px 0px #1018280d;
}
.loginCode .addInfrmtnArea .extrafield input[type="text"]:focus {
  box-shadow: none; 
}

.loginCode .addInfrmtnArea #EducationLevelInput,
.loginCode .addInfrmtnArea #FieldOfEducationInput,
.loginCode .addInfrmtnArea #CurrentIndustryInput {
  input[type="text"] {
    border: none;
    box-shadow: none;
  }
}
.loginCode .addInfrmtnArea .input-group:focus-within {
  border-radius: 9px;
  border: 2px solid #8098f9;
  box-shadow: 0px 0px 0px 2px hsl(267, 100%, 96%), 0px 1px 2px 0px #1018280d;
}

.loginCode .addInfrmtnArea input[type="number"]:focus {
  outline: none;
  border-color: #8098f9;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.loginCode .addInfrmtnArea .form-selection {
  width: 100%;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.formArea #codeInput {
  /* border: 1px solid #D0D5DD; */
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.formArea #codeInput::placeholder {
  color: #667085;
}
.formArea #codeInput:focus {
  /* box-shadow: 0 0 4px rgba(0, 123, 255, 0.502);
  border: 1px solid #8098F9; */
  outline: none;
  border-color: #a4bcfd;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.formArea #codeInput .input:-internal-autofill-selected {
  background-color: grey !important;
}
.loginCode .addInfrmtnArea .form-selection .css-1fdsijx-ValueContainer {
  border: none;
}
.loginCode .addInfrmtnArea .form-selection .css-1fdsijx-ValueContainer :hover,
.loginCode
  .addInfrmtnArea
  .form-selection
  .css-1hb7zxy-IndicatorsContainer
  :hover {
  border: none;
}
/* .loginCode .addInfrmtnArea .form-selection :hover{
  border: 1px solid #D0D5DD;
} */

.loginCode .addInfrmtnArea .form-selection .css-t3ipsp-control:focus-within {
  /* outline: 1px solid white !important; */
  /* border: 1px solid #a4bcfd; */
  border-radius: 9px;
  box-shadow: 0px 0px 0px 3px #f4ebff, 0px 1px 2px 0px #1018280d;
}

.loginCode .addInfrmtnArea .form-selection .css-t3ipsp-control:hover {
  border: 1px solid #8098f9;
}
.loginCode .addInfrmtnArea .form-selection option {
}
.loginCode .addInfrmtnArea select:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.date-picker-container .react-datepicker-wrapper {
  display: block;
}

.aiWrap {
  display: flex;
  background-color: #ffffff;
}
.loginCode .addInfrmtnArea input[type="date"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .addInfrmtnArea input[type="date"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
.loginCode .addInfrmtnArea input[type="file"] {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #d0d5dd;
  border-radius: 8px !important;
  box-shadow: 0px 1px 2px 0px #1018280d;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Inter-Regular";
  color: #101828;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}
.loginCode .addInfrmtnArea input[type="file"]:focus {
  outline: none;
  border-color: #a4bcfd;
  box-shadow: 0px 0px 0px 4px #f4ebff, 0px 1px 2px 0px #1018280d;
}
/* .addInfrmtnArea .form-select{
  width: 200px;
  padding: 10px;
  font-size: 16px;
} */

.uploadIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 1rem;
  height: 2rem;
  right: 0.8rem !important;
  background: white;
}
/* #AvailableFromInput::-webkit-calendar-picker-indicator {
  display: none;
}
.calendarIconStyl {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  right: 13px !important;
} */
@media screen and (max-width: 991px) {
  .illustImg {
    /* max-width: 100%; */
    width: 330px !important;
    height: auto;
  }
  .homePad {
    padding: 0 20px !important;
  }
  .formSpc {
    padding: 10px;
  }
}
@media screen and (max-width: 768px) {
  .clLft-side {
    padding: 30px;
  }
  .illustImg {
    /* max-width: 100%; */
    width: 250px !important;
    height: auto;
  }
}

/* mobile view */
@media screen and (max-width: 767px) {
  .btnVldt {
    /* display: none; */
  }
}
@media screen and (max-width: 576px) {
  .mainWrap {
    background-color: #eef4ff;
    min-height: 100vh;
    padding-top: 0;
  }
  .logoCntr {
    margin-bottom: 10px;
  }
  .splashScreen {
    height: auto;
    display: block;
    align-items: initial;
    justify-content: initial;
  }
  .padTB {
    padding: 20px 0;
  }
  .homePad {
    padding: 0 10px !important;
  }
  .subTxt {
    font-size: 16px;
    line-height: 24px;
  }
  .formArea {
    /* height: 111px; */
  }
  .mobBtn {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffff;
    padding: 10px;
    border: 1px 0px 0px 0px #eaecf0;
  }
  .formSpc {
    padding: 15px;
  }
  .btnVldtMob {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #444ce7;
    padding: 12px 20px;
    gap: 8px;
    background-color: #444ce7;
    box-shadow: 0px 1px 2px 0px #1018280d;
  }

  .btnVldtMob p {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    font-family: "Inter-SemiBold";
    color: #ffffff;
    margin: 0;
  }
  .subHdg {
    font-size: 20px;
    line-height: 30px;
  }
  .mobViewPT {
    padding: 0 10px;
  }
  .logoStyleMobPT {
    width: 32px;
    height: 32px;
  }
  .VWOcard {
    width: 120px;
    height: 120px;
  }
  .swipeIconStyleMobPT {
    width: 32px;
    height: 32px;
    object-fit: contain;
  }
  .swpRgt {
    display: flex;
    align-items: center;
    /* margin: 10px 0; */
    margin: 0 0 10px 0;
  }
  .swpLft {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 10px 0;
  }
  .swpTxt {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: "Inter-Regular";
    color: #0c111d;
  }
  .swpTxtMob {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    font-family: "Inter-Regular";
    color: #344054;
  }
  .subTxtRW {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    font-family: "Inter-Regular";
    color: #344054;
    margin-top: 10px;
  }
  .clWrap {
    display: block;
    background-color: #ffffff;
    height: 100vh;
  }
  .clLft-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 200px;
    background-color: #f9fafb;
    padding: 24px 20px 24px 20px;
  }

  .illustrationContainer {
    position: static;
    margin-top: 50px;
  }

  .clLogoStyleMob {
    left: 20px !important;
    position: absolute;
    display: flex;
  }
  .clLogoStyle {
    position: static;
  }
  .illustImg {
    max-width: 100%;
    height: 120px;
  }
  .btnVldt {
    width: 100%;
  }
  /* .indctor {
    bottom: 70px;
    width: 100%;
    padding: 10px;
    background-color: #eef4ff;
  } */
  .aiWrap {
    display: block;
    background-color: #ffffff;
  }
}
@media screen and (max-width: 393px) {
  .splashScreen {
    height: 100%;
    /* padding-bottom: 50px; */
  }
  .padIn {
    padding: 14px 32px 40px 32px;
    .logoCntr {
      margin-bottom: 5px;
    }
  }

  .hdg {
    font-size: 17px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .subTxt {
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .swpTxt {
    font-size: 14px;
  }
  .mobViewPT .subHdg {
    font-size: 17px;
    line-height: 30px;
    margin-top: 0px;
  }
  .padTB {
    padding: 10px 0px;
  }
  .VWOcard {
    width: 87px;
    height: 85px;
  }
  .title {
    width: 140px;
    height: 30px;
  }
  .formSpc {
    margin-bottom: 7rem;
  }

  .loginCode label {
    display: block;
    margin-bottom: 5px;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    font-family: "Inter-Medium";
    color: #344054;
  }
  .loginCode .formArea input[type="text"] {
    padding: 4px 8px;
    font-size: 14px;
  }
  .loginCode .addInfrmtnArea input[type="text"],
  .loginCode .addInfrmtnArea input[type="number"] {
    padding: 4px 8px;
    font-size: 15px;
  }
  .btnVldt p {
    font-size: 14px !important;
  }
  .loginCode p {
    font-size: 25px;
  }
  .addInfsubTxt p {
    font-size: 12px;
  }
}

@media (min-width: 576px) {
  .webViewPT {
    display: block;
  }
  .mobViewPT {
    display: none;
  }
}
@media (max-width: 575.99px) {
  .mobBtn {
    display: block;
  }
  .webViewPT {
    display: none;
  }
  .mobViewPT {
    display: block;
    margin-bottom: 30px;
  }
}
::-webkit-scrollbar {
  display: none;
}
.text-green{
  color: #47CD89!important;
} 
