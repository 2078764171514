/* ------------------companyName-------------- */
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#CompanyName-main p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #667085;
  margin: 0;
  font-family: "Inter-Medium";
}
#CompanyName-main h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  color: #000;
  font-family: "Inter-Bold";
}
/* .loginCode .addInfrmtnArea input[type="text"]  {
  width: 440px;
} */
.nums {
  width: 32px;
  height: 32px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sideNum-inweb {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  padding: 5px 5px 6% 5px;
  height: auto;
}

.sideNum-inweb .padding-in-web {
  padding-top: 16px;
}
.vector-img {
  width: 24px;
  height: 24px;
}
#company-rightside .padding-in-web {
  padding-top: 16px;
}
#company-rightside h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  font-family: "Inter-Bold";
}
#company-rightside h2 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  color: #667085;
  font-family: "Inter-SemiBold";
}
.clr-text-Enthusiastic {
  color: #6172f3;
}
.clr-text-Cautious {
  color: #f97066;
}
.company-rightside {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
}
.company-rightside-part1 {
  padding: 10px 5px;
}
.company-rightside-part2 {
  padding: 10px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
}
#company-rightside li {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #667085;
  gap: 8px;
  padding: 5px;
  font-family: "Inter-Regular";
}
#company-centerside h2 {
  color: #344054;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  font-family: "Inter-SemiBold";
}
#company-centerside .option-name {
  color: #344054;
  font-size: 27px;
  font-weight: 600;
  line-height: 30px;
  color: black;
}
.company-box {
  width: 40rem;
  height: auto;
  background-color: #fff;
  display: flex;
  padding: 24px 22px;
  border: 2px solid;
  border-radius: 12px;
  border: solid 1px #ffffff;
  /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
  box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
  margin: 10px 0;
  .company-img {
    width: 13rem;
    height: auto;
  }
}
.thumbsdown-comp {
  width: 1.25rem;
  height: auto;
}

.thumbsdown-comp-link {
  position: relative;
  display: inline-block;
  padding: 8px 12px 8px 12px;
  text-decoration: none;
  background-color: #fcfcfd;
  border-radius: 8px;
  border: solid 1px #d0d5dd;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: padding-left 0.5s ease;
}
.gapp {
  gap: 8px;
}

.thumbsdown-comp-link:hover .hover-text {
  opacity: 1;
  margin: 1px -70px 1px 1px;
}
.thumbsdown-comp-link:hover {
  padding-left: 70px;
}

.thumbsup-comp-link {
  position: relative;
  display: inline-block;
  padding: 8px 12px 8px 12px;
  text-decoration: none;
  background-color: #fcfcfd;
  border-radius: 8px;
  border: solid 1px #d0d5dd;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  overflow: hidden;
  transition: padding-left 0.5s ease;
}

.hover-text {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease, margin-right 0.3s ease, right 0.3s ease;
  padding-left: 5px;
  padding-right: 5px !important;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #667085;
  font-family: "Inter-Regular";
  width: 70px;
  text-align: end;
}

.thumbsup-comp-link:hover .hover-text {
  opacity: 1;
  margin: 1px -60px 1px 1px;
}
.thumbsup-comp-link:hover {
  padding-left: 60px;
}
.comp-mainHead-txt {
  margin: 30px;
}
.company-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 8px 7px;
  background-color: #fff;
  border-top: 1px solid #eaecf0;
  border-color: #eaecf0;
}

.comp-prebtn {
  background-color: #eef4ff;
  border: solid 1px #c7d7fe;
  color: #3538cd;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  font-family: "Inter-SemiBold";
  text-decoration: none;
}
.comp-prebtn:hover {
  background-color: #e0eaff;
  border: 1px solid #c7d7fe;
  color: #2d31a6;
}
.comp-nxtbtn {
  background-color: #444ce7;
  border: solid 1px #444ce7;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  padding: 8px 14px;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  font-family: "Inter-SemiBold";
}
.comp-nxtbtn:hover {
  background-color: #3538cd;
  border: 1px solid #3538cd;
  color: #ffffff;
}
.width-carddiv {
  width: 100%;
}

.edit-btn {
  background-color: #444ce7;
  border: solid 1px #444ce7;
  border-radius: 8px;
  color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  padding: 8px 14px;
  margin-left: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  font-family: "Inter-SemiBold";
}
.edit-btn:hover {
  background-color: #3538cd;
  border: 1px solid #3538cd;
  color: #ffffff;
}
.arrowleft {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.arrowright {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
.comp-savebtn {
  background-color: #eef4ff;
  border: solid 1px #c7d7fe;
  color: #3538cd;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  font-family: "Inter-SemiBold";
}
.comp-savebtn:hover {
  background-color: #e0eaff;
  border: 1px solid #c7d7fe;
  color: #2d31a6;
}
.modaltoast {
  width: 70%;
  background-color: #ffffff;
  position: fixed;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  bottom: -100%;
  left: 50%;
  transform: translateX(-50%);
  transition: bottom 0.5s ease;
  border-radius: 16px;
  border: solid 1px #c7d7fe;
  box-shadow: 0 1px 1px 0 rgba(16, 24, 40, 0.5);
}
.modaltoast.show {
  bottom: 20%;
}
.Tasty_Cookies {
  width: 80px;
  height: 80px;
}
.CompanyName-mainmbl {
  display: none;
}
/* .bottomSheet{
  display: none;
} */
.CompanyName-maindis {
  background-color: #fcfcfd;
  .relating {
    position: fixed;
    top: 84px;
  }
}
/* .relating{
  position: absolute;
  top: 100px;
} */
.company-bottom-mbl {
  display: none;
}
.partmbl3 {
  padding: 0 5px;
}
.disabled {
  pointer-events: none;
  /* opacity: 0.5; */
}
.bottomSheet {
  display: none;
}
.sidnum-pad {
  padding-left: 10px;
  padding-right: 6px;
}
.hide {
  display: none;
}
.thank-main .check-wrapper {
  width: 7rem;
  height: auto;
}
.border-blue {
  border: 1px solid #444ce7 !important;
  transition: 0.4s linear !important;
}
.border-red {
  border: 1px solid #f97066 !important;
  transition: 0.4s linear !important;
}
/* .not-display-it{
  display: none;
}
.display-it{
  display: block;
} */

/* .card-column{
  width: 450px;
  height: 218px;
} */
/* ------------------companyName( media query )-------------- */
@media screen and (max-height: 900px) {
  .sideNum-inweb {
    height: 100%;
    width: 10rem !important;
    /* box-shadow: 0px 1px 3px 0px #949494; */
    .nums {
      margin-top: -5px;
    }
  }
}
@media screen and (min-height: 1000px) {
  .sideNum-inweb {
    .nums {
      margin-top: 15px;
    }
  }
}
@media screen and (min-width: 1201px) {
  .sidnum-pad {
    .sidenum-top-padding {
      margin-top: 5px;
      padding-top: 7px;
      padding-inline: 5px;
    }
  }
}

@media screen and (max-width: 1280px) {
  /* .hiding-column .img-color{
    filter: invert(19%) sepia(28%) saturate(100%) hue-rotate(90deg) brightness(89%) contrast(84%);
  } */
  .sideNum-inweb .nums {
    margin-top: 10px !important;
  }
  .company-box {
    /* width: 30rem;
    height: auto; */
    background-color: #fff;
    display: flex;
    padding: 24px 22px;
    border: 2px solid;
    border-radius: 12px;
    border: solid 1px #ffffff;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    .company-img {
      width: 9rem;
      height: auto;
    }
    .option-name {
      font-size: 23px;
      font-weight: 600;
      line-height: 30px;
      color: black;
    }
  }
  .rightlike {
    height: 100%;
    border-radius: 0px 12px 12px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444ce7;
  }
  .leftunlike {
    height: 100%;
    width: auto;
    border-radius: 12px 0px 0px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f97066;
  }
  @keyframes expandboth {
    0% {
      width: 0%;
    }
    50% {
      width: 20%;
    }
    100% {
      width: 0%;
    }
  }
  .unlike-animation,
  .like-animation {
    animation: expandboth 600ms linear;
  }
  @keyframes movingright {
    0% {
      transform: translate(0rem);
    }
    50% {
      transform: translate(3rem);
    }
    100% {
      transform: translate(0rem);
    }
  }
  .move-right {
    animation: movingright 600ms linear;
    animation-direction: alternate;
  }
  @keyframes movingleft {
    0% {
      transform: translate(0rem);
    }
    50% {
      transform: translate(-3.5rem);
    }
    100% {
      transform: translate(0rem);
    }
  }
  .move-left {
    animation: movingleft 600ms linear;
  }
  /* @keyframes displayAni {
    0%{
     width: 0%;
    }
    50%{
      width: 20%;
    }
    100%{
      width: 0%;
    }
  }
  .visible-leftanimation{
    animation: displayAni 3s linear;
    background-color: #000;
  } */

  @keyframes expand {
    0% {
      width: 0%;
    }
    50% {
      width: 17%;
    }
    100% {
      width: 0%;
    }
  }

  .expand-animation {
    animation-duration: 2s;
    animation-name: expand;
  }

  @keyframes squishBlue {
    0% {
      transform: translate(0rem);
    }
    50% {
      transform: translate(-2.2rem);
    }
    100% {
      transform: translate(0rem);
    }
  }
  .squish-blue {
    animation-duration: 2s;
    animation-name: squishBlue;
  }

  @keyframes squishOrange {
    0% {
      transform: translate(0rem);
    }
    50% {
      transform: translate(2.2rem);
    }
    100% {
      transform: translate(0rem);
    }
  }
  .squish-orange {
    animation-duration: 2s;
    animation-name: squishOrange;
    animation-direction: alternate;
  }
  /* .thumbsdown-comp {
    width: 1.25rem;
    height: auto;
  } */

  .thumbsdown-comp-link {
    position: relative;
    display: inline-block;
    padding: 8px 12px 8px 12px;
    text-decoration: none;
    background-color: #fcfcfd;
    border-radius: 8px;
    border: solid 1px #d0d5dd;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: padding-left 0.5s ease;
  }
  /* .gapp {
    gap: 8px;
  } */

  .thumbsdown-comp-link:hover .hover-text {
    opacity: 1;
    margin: 1px -70px 1px 1px;
  }

  .thumbsdown-comp-link:hover {
    padding-left: 70px;
  }
  .thumbsup-comp-link {
    position: relative;
    display: inline-block;
    padding: 8px 12px 8px 12px;
    text-decoration: none;
    background-color: #fcfcfd;
    border-radius: 8px;
    border: solid 1px #d0d5dd;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    overflow: hidden;
    transition: padding-left 0.5s ease;
  }

  .hover-text {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    opacity: 0;
    transition: opacity 0.3s ease, margin-right 0.3s ease, right 0.3s ease;
    padding-left: 5px;
    /* margin-right: -5px; */
    padding-right: 5px !important;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #667085;
    font-family: "Inter-Regular";
    width: 70px;
    text-align: end;
  }

  .thumbsup-comp-link:hover .hover-text {
    opacity: 1;
    margin: 1px -60px 1px 1px;
  }

  .thumbsup-comp-link:hover {
    padding-left: 60px;
  }
  @keyframes delaydown {
    0% {
      background-color: #f9fafb;
      border-color: #d0d5dd;
      cursor: pointer;
      border: 1px solid #d0d5dd;
    }
    50% {
      background-color: #f9fafb;
      cursor: pointer;
      border: 1px solid #d0d5dd;
    }
    100% {
      border-color: #f97066;
      background-color: #f97066;
      cursor: pointer;
      border: 1px solid #f97066;
    }
  }
  .changewithDelaydown {
    .selectedColor {
      color: #a6a9ad !important;
    }
    .thumbsdown-comp {
      filter: invert(60%) sepia(6%) saturate(100%) hue-rotate(100deg)
        brightness(40%) contrast(10%) !important;
    }
    animation: delaydown 2s linear;
  }

  @keyframes delayup {
    0% {
      border-color: #d0d5dd;
      background-color: #f9fafb;
      cursor: pointer;
      border: 1px solid #d0d5dd;
      color: #000;
    }
    50% {
      border-color: #d0d5dd;
      background-color: #f9fafb;
      cursor: pointer;
      border: 1px solid #d0d5dd;
      color: #000;
    }
    100% {
      border-color: #444ce7;
      background-color: #444ce7;
      cursor: pointer;
      border: 1px solid #444ce7;
      color: white;
    }
  }
  .changewithDelayup {
    .selectedColor {
      color: #a6a9ad !important;
    }
    .thumbsup-comp {
      filter: invert(60%) sepia(6%) saturate(100%) hue-rotate(100deg)
        brightness(40%) contrast(10%) !important;
    }
    animation: delayup 2s linear;
  }

  .likeSelected {
    border-color: #444ce7;
    background-color: #444ce7;
    cursor: pointer;
    border: 1px solid #444ce7;
  }
  .DislikeSelected {
    border-color: #f97066;
    background-color: #f97066;
    cursor: pointer;
    border: 1px solid #f97066;
  }
}

@media screen and (max-width: 1200px) {
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 27rem;
    height: auto;
    .company-img {
      width: 8rem;
      height: auto;
    }
  }
  /* .company-img{
    width: 10rem;
    height: auto;
  } */
  #company-centerside .option-name {
    font-size: 24px;
  }
  .sidnum-pad {
    .sidenum-top-padding {
      padding: -1px;
    }
  }
  /* .nums {
    width: 40px;
    height: 40px;
    border: 1px solid #d0d5dd;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
}
@media screen and (max-width: 2400px) {
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 33rem;
    height: auto;
    .company-img {
      width: 12rem;
      height: auto;
    }
  }
  #company-centerside .option-name {
    font-size: 30px;
  }
}
/* @media screen and (max-width: 1050px) {
  .company-box{
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 27rem;
    height: auto;
    .company-img{
      width: 5rem;
      height: auto;
    }
  }
  #company-centerside .option-name{
    font-size: 22px;
  }
}

@media screen and (max-width: 1300px) {
  .company-box{
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 25rem;
    height: auto;
    .company-img{
      width: 7.5rem;
      height: auto;
    }
  }
  #company-centerside .option-name{
    font-size: 23px;
  }
} */
.bottom-buttons{
  display: flex;
  justify-content: space-between;
  align-items:center; 
}
@media screen and (max-width: 1500px) {
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 25rem;
    height: auto;
    .company-img {
      width: 7rem;
      height: auto;
    }
  }
  #company-centerside .option-name {
    font-size: 23px;
  }
}
@media screen and (max-width: 1100px) {
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 16px 16px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 25rem;
    height: auto;
    .company-img {
      width: 6rem;
      height: auto;
    }
  }
  #company-centerside .option-name {
    font-size: 22px;
  }
}

@media screen and (max-width: 950px) {
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 11px 16px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 23rem;
    height: auto;
    .company-img {
      width: 7rem;
      height: auto;
    }
  }
  #company-centerside .option-name {
    font-size: 24px;
  }
}
@media screen and (max-width: 768px) {
  .company-bottom-mobile{
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 8px 7px;
    background-color: #fff;
    border-top: 1px solid #eaecf0;
    border-color: #eaecf0;
  }
  .CompanyName-mainmbl {
    display: flex;
    padding-bottom: 7rem;
    background-color: #f9fafb;
  }
  /* .CompanyName-maindis {
    display: none;
  } */
  .company-bottom-mbl {
    display: flex;
    padding: 10px;
    border-top: 1px solid #eaecf0;
    border-color: #eaecf0;
    bottom: 0;
    position: fixed;
    width: 100%;
    background: #fff;
  }
  .bottomSheet {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: #f5f8ff;
    transform: translateY(-57%);
    transition: transform 0.5s ease;
    height: 60px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    padding: 5px;
    .manage-cols {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
    }
  }
  .bottomSheet.active{
    position: fixed;
    bottom: -38px;
  }
  @keyframes riseup {
    0% {
      transform: translateY(10%);
      height: 10%;
    }
    /* 50%{
      transform: translateY(-20%);
      height: 30%;
    } */
    100% {
      transform: translateY(-20%);
      height: 26%;
    }
  }
  .bottomSheet.active {
    animation: riseup 700ms linear;
    height: 28%;
  }
  @keyframes setdown {
    0% {
      transform: translateY(-20%);
      height: 30%;
    }
    /* 50%{
      transform: translateY(-20%);
      height: 30%;
    } */
    100% {
      transform: translateY(-10%);
      height: 10%;
    }
  }

  .bottomSheet.inactive {
    animation: setdown 700ms linear;
    
  }
  .remove-translation{
    
  }
  /* .bottomSheet.active {
    transform: translateY(-20%);
    height: 30%;
  } */
  /* .company-bottom {
    display: none;
  } */
  .attempted,
  /* .comp-mainHead-txt, */
  .company-rightside-part1,
  .company-rightside-part2 {
    display: none;
  }
  .comp-mainHead-txt {
    margin: 0px;
  }
  #modaltoast p {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    color: #667085;
    margin: 0;
    font-family: "Inter-Regular";
  }
  .Tasty_Cookies {
    width: 40px;
    height: 40px;
  }
  .modaltoast {
    width: 90%;
    background-color: #ffffff;
  }
  .company-img {
    width: 11rem;
    height: auto;
    /* width: 100%;
    height: 100%; */
    /* height: 110px; */
  }
  .comp-prebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    color: #3538cd;
    font-size: 12px;
    font-weight: 400;
  }
  .comp-nxtbtn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 10px;
    margin-left: 15px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }
  .edit-btn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    margin-right: 15px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }
  .arrowleft {
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }
  .arrowright {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
  .comp-savebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 10px;
    color: #3538cd;
    font-size: 12px;
    font-weight: 400;
  }
  .cards-container {
    margin-bottom: 5.7rem;
  }
  .sideNum-inweb {
    display: none !important;
  }
  #company-centerside h2 {
    color: #344054;
    font-size: 13px;
    font-weight: 600;
    line-height: 1rem;
    font-family: "Inter-SemiBold";
    margin-bottom: 0px;
  }
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 27rem;
    height: auto;
    .company-img {
      width: 11rem;
      height: auto;
    }
  }
  #company-centerside .option-name {
    color: #344054;
    font-size: 30px;
    font-weight: 600;
    line-height: 30px;
    color: black;
  }
  .bottom-buttons {
    .display-none-div {
      display: none;
    }
    display: flex;
    flex-direction: row-reverse;
  }

  .thumbsdown-comp-link:hover .hover-text {
    opacity: 0;
  }
  .thumbsdown-comp-link:hover {
    padding-left: 12px;
  }

  .thumbsup-comp-link:hover .hover-text {
    opacity: 0;
    margin: initial;
  }
  .thumbsup-comp-link:hover {
    padding-left: 12px;
  }

  .thumbsup-comp-link.hover-effect .hover-text {
    opacity: 1;
    margin: 1px -70px 1px 1px;
  }
  .thumbsup-comp-link.hover-effect {
    padding-left: 70px;
  }

  .thumbsdown-comp-link.hover-unlike .hover-text {
    opacity: 1;
    margin: 1px -70px 1px 1px;
  }
  .thumbsdown-comp-link.hover-unlike {
    padding-left: 70px;
  }
  .place-below-header {
    position: "absolute";
    top: 70px;
    left: 46%;
    z-index: 999;
  }
  .show-above {
    position: "absolute";
    top: -10px;
    left: 46%;
    z-index: 999;
  }
  .drop-img {
    border: 1px solid #a0a5b1;
    border-radius: 6px;
  }
  #company-centerside {
    margin-top: 10px;
    margin-bottom: 15px;
    /* margin-top: 15px; */
    /* margin-bottom: 2rem; */
  }
}
@media screen and (max-width: 460px) {
  .place-below-header {
    position: "absolute";
    top: 52px;
    left: 46%;
    z-index: 999;
  }
  .show-above {
    position: "absolute";
    top: -10px;
    left: 46%;
    z-index: 999;
  }
}

@media screen and (max-width: 401px) {
  .edit-btn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    margin-right: 15px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }
  .comp-prebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    color: #3538cd;
    font-size: 12px;
    font-weight: 400;
  }
  .comp-nxtbtn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    margin-left: 15px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 400;
  }
  .comp-savebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    color: #3538cd;
    font-size: 12px;
    font-weight: 400;
  }
}
#thank-main h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 600;
  font-family: "Inter-SemiBold";
  color: #344054;
}
#thank-main p,
.interested {
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
  font-family: "Inter-Regular";
  color: #344054;
}
.Lywologo {
  width: 120px;
  height: 40px;
  margin-bottom: 16px;
}
.mail {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.comp-mailbtn {
  background-color: #eef4ff;
  border: solid 1px #c7d7fe;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 14px;
  color: #3538cd;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  font-family: "Inter-SemiBold";
}

.thank-main2 {
  border-top: 1px solid #c7d7fe;
  border-color: #c7d7fe;
  padding: 20px 0;
  background-color: #fff;
  /* gap: 16px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding-bottom: 4rem;
}

/* ------------------break------------------ */
.Illustration1-slide {
  width: 230px;
  height: 200px;
}
.breakmain {
  background-color: #fff;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90vh;
}
#breakmain h3 {
  font-size: 48px;
  line-height: 60px;
  font-weight: 700;
  color: #081da5;
  font-family: "Inter-Bold";
}
#breakmain p {
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  color: #344054;
  font-family: "Inter-Regular";
}
@media screen and (max-width: 576px) {
  .Illustration1-slide {
    width: 180px;
    height: 150px;
  }
  .breakmain {
    background-color: #fff;
    padding: 40px 0;
  }
  #breakmain h3 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    color: #081da5;
  }
  #breakmain p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    color: #344054;
    font-family: "Inter-Regular";
  }
  .thumbsup-comp-link {
    padding: 5px 8px;
  }
  .thumbsdown-comp-link {
    padding: 5px 8px;
  }
  .nums {
    width: 25px;
    height: 25px;
  }
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 20px 16px;
    border-radius: 12px;
    border: none;
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 23rem;
    height: auto;
    .company-img {
      width: 5.5rem;
      height: auto;
    }
  }
  .thank-main {
    height: auto;
    margin-bottom: 0;
  }
  #thank-main h2 {
    font-size: 30px;
    line-height: 38px;
  }
  #thank-main p,
  .interested {
    font-size: 16px;
    font-weight: 400;
    /* line-height: 28px; */
    font-family: "Inter-Regular";
    color: #344054;
  }
  .thank-main2 {
    position: fixed;
    bottom: 0;
    width: auto;
  }
  .bottomSheet {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: #f5f8ff;
    transform: translateY(-56%);
    transition: transform 0.5s ease;
    height: 60px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    padding: 5px;
    .manage-cols {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }
  }
  /* .bottomSheet.active {
    transform: translateY(-20%);
    height: 30%;
  } */
  #company-centerside .option-name {
    color: #344054;
    font-size: 19px;
    font-weight: 300;
    line-height: 30px;
    color: black;
  }
}
.bottomSheet {
  .pertxt {
    font-size: 20px;
    font-weight: 700;
    line-height: 15px;
    color: #000000;
    font-family: "Inter-Bold";
  }
  #CompanyName-main p {
  }
}
@media screen and (max-width: 460px) {
  #mbl-att h2 {
    font-size: 15px;
  }
  #Header-main h2 {
    font-size: 15px;
    font-weight: 700;
    line-height: 13px;
    color: #000;
    font-family: "Inter-Bold";
  }
}
@media screen and (max-width: 393px) {
  #Header-main {
    width: 100%;
  }
  .bottomSheet {
    width: 100%;
    height: 69px;
    /* .hundredper{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    } */
    .pertxt {
      opacity: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      color: #000000;
      font-family: "Inter-Bold";
    }
    #mbl-att h2 {
      font-size: 15px;
    }
  }
  #Header-main p {
    font-size: 10px;
    font-weight: 500;
    line-height: 18px;
    color: #667085;
    margin: 0;
    font-family: "Inter-Medium";
  }
  #Header-main h2 {
    font-size: 13px;
    font-weight: 700;
    line-height: 12px;
    color: #000;
    font-family: "Inter-Bold";
  }
  .thank-main2 {
    padding: 10px 0px;
  }
  .thank-main .check-wrapper {
    width: 6rem;
    height: auto;
  }
  #thank-main h2 {
    font-size: 26px;
    line-height: 26px;
  }
  #thank-main p,
  .interested {
    font-size: 12px;
    font-weight: 400;
    /* line-height: 28px; */
    font-family: "Inter-Regular";
    color: #344054;
  }
  .custom-dropdown-toggle {
    border: solid 1px #1018280f;
    padding: 10px 12px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  }
  .company-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 10px 13px;
    background-color: #fff;
    border-top: 1px solid #eaecf0;
    border-color: #eaecf0;
  }
  #CompanyName-main p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #667085;
    margin-inline: -10px;
    font-family: "Inter-Medium";
    margin-left: -7px !important;
  }
  .progress {
    margin-left: -16px;
    margin-right: 60px;
  }
  #mbl-att h2 {
    font-size: 9px;
    font-weight: 700;
    line-height: 15px;
    color: #000;
    margin: 0;
    font-family: "Inter-SemiBold";
  }
  .comp-prebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 4px;
    color: #3538cd;
    font-size: 9px;
    font-weight: 400;
  }
  .comp-nxtbtn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 5px;
    margin-left: 15px;
    color: white;
    font-size: 9px;
    font-weight: 400;
  }
  .edit-btn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 3px;
    margin-right: 15px;
    color: white;
    font-size: 9px;
    font-weight: 400;
  }
  .comp-savebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 5px 4px;
    color: #3538cd;
    font-size: 9px;
    font-weight: 400;
  }
  #mbl-att h2 {
    font-size: 15px;
    font-weight: 700;
    line-height: 15px;
    color: #000;
    margin: 0;
    font-family: "Inter-SemiBold";
  }
  .company-box {
    background-color: #fff;
    display: flex;
    padding: 0px 0px;
    border-radius: 12px;
    border: none;
    /* box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a; */
    box-shadow: 0px 1px 3px 0px #949494, 0px 1px 3px 0px #1018281a;
    margin: 10px 0;
    width: 20rem;
    height: 6.5rem;
    .company-img {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 5.5rem;
      height: auto;
    }
  }
  .rowww {
    /* width: 350px; */
    width: auto;
  }
  .cards-container {
    /* width: 350px; */
    width: auto;
  }
  .padding-small-screen {
    padding: 7px 4px;
  }
  /* .hiding-column{
  height: 100%;
  border-radius: 12px 0px 0px 12px;
  display: flex;
  justify-content: center;
  align-items: center;

}


@keyframes expand {
  0% {
    width: 0rem;
  }
  50% {
    width: 3.5rem;
  }
  100% {
    width: 0rem;
  }
}

.expand-animation {
  animation-duration: 3s;
  animation-name: expand;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes squishBlue {
  0% {
    transform: translate(0rem);
  }
  50% {
    transform: translate(2rem);
   
  }
  100% {
    transform: translate(0rem);
  }  
}
.squish-blue {
  animation-duration: 3s;
  animation-name: squishBlue;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.squish-orange {
  animation-duration: 3s;
  animation-name: squishOrange;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes squishOrange {
  0% {
    transform: translate(0rem);
  }
  50% {
    transform: translate(2rem);
  }
  100% {
    transform: translate(0rem);
  }
} */
  .bottomSheet {
    display: block;
    position: fixed;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: #f5f8ff;
    transform: translateY(-57%);
    transition: transform 0.5s ease;
    height: 60px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
    justify-content: center;
    padding: 5px;
    .manage-cols {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
    }
  }
  .manage-height {
    /* padding-bottom: 5px; */
  }
}
@media screen and (max-width: 365px) {
  .nums {
    width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 295px) {
  .nums {
    width: 24px;
    height: 24px;
  }
}
@media screen and (max-width: 325px) {
  .edit-btn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    margin-right: 15px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    text-wrap: nowrap;
  }
  .comp-prebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    color: #3538cd;
    font-size: 10px;
    font-weight: 400;
    text-wrap: nowrap;
  }
  .comp-nxtbtn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    margin-left: 15px;
    color: #ffffff;
    font-size: 10px;
    font-weight: 400;
    text-wrap: nowrap;
  }
  .comp-savebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    color: #3538cd;
    font-size: 10px;
    font-weight: 400;
    text-wrap: nowrap;
  }
}
@media screen and (max-width: 316px) {
  .edit-btn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    margin-right: 8px;
    color: #ffffff;
    font-size: 8px;
    font-weight: 400;
    text-wrap: nowrap;
    width: 3rem;
  }
  .comp-prebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 0px;
    color: #3538cd;
    font-size: 8px;
    width: 3.5rem;
    font-weight: 400;
    text-wrap: nowrap;
  }
  .comp-nxtbtn {
    background-color: #444ce7;
    border: solid 1px #444ce7;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    margin-left: 8px;
    color: #ffffff;
    font-size: 8px;
    width: 2.6rem;
    font-weight: 400;
    text-wrap: nowrap;
  }
  .comp-savebtn {
    background-color: #eef4ff;
    border: solid 1px #c7d7fe;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    cursor: pointer;
    border-radius: 8px;
    padding: 0px 4px;
    color: #3538cd;
    font-size: 8px;
    font-weight: 400;
    text-wrap: nowrap;
  }
}

.bottomSheetscroll {
  /* overflow-y: scroll; */
  height: 196px;
  gap: 12px;
  padding-bottom: 70px;
  padding-top: 5px;
}
.bottomSheetscroll::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: hide scrollbar gutter */
}

/* .bottomSheet.active {
  transform: translateY(-20%);
  height: 30%;
} */

.openButtonWrapper {
  margin-top: auto;
}
.bore {
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid #000;
}
.rowww {
  align-items: center;
  padding: 6px 0px;
}
.rownum {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  white-space: nowrap;
}
.drag-img {
  width: 56px;
  height: 24px;
  cursor: pointer;
}
.drag-imgdiv {
  position: fixed;
  left: 43%;
  cursor: pointer;
  margin-top: 0;
  top: -25px;
  img {
    border-top: 1px solid black;
    border-right: 1px solid black;
    border-left: 1px solid black;
    border-radius: 8px 8px 0px 0px;
    background-color: white;
  }
}
#mbl-att h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 15px;
  color: #000;
  margin: 0;
  font-family: "Inter-SemiBold";
}
.mbl-att {
  padding-top: 10px;
}
.nums-link {
  text-decoration: none;
  color: #1d2939;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  cursor: pointer;
  font-family: "Inter-Regular";
}
.nums-link-change {
  text-decoration: none;
  /* color: #15b79e; */
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  font-family: "Inter-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
}

.comp-prebtnmbl {
  /* background-color: red; */
  font-size: 12px;
  background-color: #eef4ff;
  border: solid 1px #c7d7fe;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  border-radius: 8px;
  padding: 2px 7px;
  color: #3538cd;
  font-family: "Inter-Regular";
  text-decoration: none;
}
.comp-nxtbtnmbl {
  background-color: #444ce7;
  border: solid 1px #444ce7;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  cursor: pointer;
  border-radius: 8px;
  padding: 2px 10px;
  margin-left: 15px;
  color: white;
  font-size: 12px;
  font-family: "Inter-Regular";
  text-decoration: none;
}
.selected {
  border-color: #15b79e;
  background-color: #f0fdf9;
  color: #15b79e;
  cursor: pointer;
  border: 1px solid #15b79e;
}
.selected-notanswered {
  border: 1px solid black;
  color: black;
  background-color: #f1f0f09b;
}
.currentSelected {
  border: 1px solid #107569;
  background-color: #ccfbef;
}
.currentSelected {
  border: 1px solid #1a4e46;
}
.likeSelected {
  border-color: #444ce7;
  background-color: #444ce7;
  cursor: pointer;
  border: 1px solid #444ce7;
}
.DislikeSelected {
  border-color: #f97066;
  background-color: #f97066;
  cursor: pointer;
  border: 1px solid #f97066;
}
.selectedColor {
  color: white;
}
/* ///////// */

.company-box {
  background-color: #fff;
  /* transition: width 0.5s ease, left 0.5s ease, right 0.5s ease; */
  position: relative;
  left: 0;
  right: 0;
}
.like-border {
  border: solid 1.3px #6172f3;
}

.unlike-border {
  border: solid 1.3px #f97066;
}
.expanded .company-box {
  width: 80%;
  left: 20%;
}

.company-box-default {
  background-color: #fff;
  display: flex;
  border-radius: 12px;
  border: solid 1px #d0d5dd;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  margin-bottom: 15px;
}

.company-box-unlike {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: solid 1px #f97066;
  box-shadow: 0px 1px 2px 0px #1018280d;
  margin-bottom: 15px;
  background-color: #f97066;
  background-image: url("../images/thumbsdownWhite.svg");
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-size: 25px;
  width: 10%;
  left: 20%;
}

.company-box-like {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: solid 1px #6172f3;
  box-shadow: 0px 1px 2px 0px #1018280d;
  margin-bottom: 15px;
  background-color: #6172f3;
  background-image: url("../images/thumbsupWhite.svg");
  background-repeat: no-repeat;
  background-position: left 20px center;
  background-size: 25px;
  width: 10%;
  right: 20%;
}
.custom-col {
  width: 40px !important;
  padding: 0 !important;
}
.lang-style {
  border-radius: 0.2rem;
  cursor: pointer;
  border: none;
  font-size: 0.9rem;
  padding: 0.5rem 2rem;
  margin: 0.2rem;
}
@media screen and (max-width: 767px) {
  .bottom-buttons{
    display: none!important;
  }
  .lang-style {
    border-radius: 0.3rem;
    cursor: pointer;
    border: none;
    font-size: 0.6rem;
    padding: 0.3rem 1.5rem;
  }
}
.small-screen-font {
  font-size: 0.67rem;
  margin-bottom: 0px;
  margin-left: 3px;
}
.customInput {
  border: 0px solid white !important;
  box-shadow: none !important;
}
.customInput:focus {
  border: none;
}
.select__value-container {
  .select__placeholder {
  }
}
.css-t3ipsp-control {
  border-color: rgb(228, 228, 228) !important;
  box-shadow: none !important;
}
@keyframes gettingup {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-50px);
  }
}
.getup {
  animation: gettingup 2s linear;
}
@media screen and (min-width: 1700px) {
  #CompanyName-main {
    max-height: 90vh;
    overflow-y: hidden;
  }
}
.text-green{
  color: #47CD89!important;
} 
