/* ---------Header--------- */
.Header-main {
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
  border: solid 1px #1018280f;
  padding: 5px 20px;
  position: sticky;
  top: 0;
  z-index: 10;
}
.GroupImg {
  width: 35px;
  height: 32px;
}
#Header-main .height-adj {
  height: 4rem;
}
#Header-main p {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #667085;
  margin: 0;
  font-family: "Inter-Medium";
}
#Header-main h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  color: #000000;
  font-family: "Inter-Bold";
}
.dropdown-item-txt {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #667085;
  margin: 0;
  font-family: "Inter-Medium";
}
.language-box{
  margin-left: 300px!important;
}
.lang-box {
  padding: 10px 14px 10px 14px;
  border-radius: 8px;
}
.lang-box .dropdown-menu {
  /* margin-top: 10px; */
  /* --bs-dropdown-min-width: 8rem; */
  /* margin-left: -12px; */
  --bs-dropdown-min-width: 10rem;
  width: var(--bs-dropdown-min-width);
  /* min-width: var(--bs-dropdown-min-width); */
  .dropdown-item {
    font-size: 10px;
  }
}
.percentage-col {
  .pertxt {
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    color: #000000;
    font-family: "Inter-Bold";
    margin-inline-end: 5px;
  }
  .completed-text {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #667085;
    font-family: "Inter-Medium";
  }
}
.border-imgHead-r {
  border-right: 1px solid #d0d5dd;
  padding: 0 30px;
}
.custom-dropdown-toggle::after {
  display: none !important;
}
.custom-dropdown-toggle {
  width: 160px;
  border: solid 1px #1018280f;
  padding: 10px 12px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
}
.chevron-img {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}
.globe-img {
  width: 20px;
  height: 20px;
  margin-left: 7px;
  margin-right: 7px;
}
.progress {
  width: 184px;
  height: 8px !important;
  margin-bottom: 3px;
}
.Header-mainmbl {
  display: none;
}
@media screen and (max-width: 768px) {
  .Header-main {
    display: none;
  }
  .Header-mainmbl {
    position: relative;
    display: flex;
    box-shadow: 0 1px 3px 0 rgba(16, 24, 40, 0.1);
    padding: 10px 0;
    background: #fff;
    border: solid 1px #1018280f;
    padding-bottom: 0px;
  }
  .progress {
    width: 104px;
    height: 2px;
  }
}
ul.overflow-auto::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: hide scrollbar gutter */
}